.about {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
}

.about-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.about-image,
.about-text {
  flex: 1;
}

.about-image img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about-text {
  max-width: 600px;
}

.about-text h2 {
  font-size: 32px;
  color: #007BFF;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about-wrapper {
    flex-direction: column;
  }

  .about-image img {
    max-width: 100%;
  }

  .about-text {
    text-align: center;
  }
}