.turbans {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 960px;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .turbans h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .turban-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    padding: 0;
  }
  
  .turban-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .turban-item img {
    width: 200px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .turban-item h3 {
    font-size: 20px;
    color: #007BFF;
  }
  
  .turban-item p {
    font-size: 16px;
    color: #666;
    padding: 0 10px;
  }