.home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@media (max-width: 768px) {
  .video-background video {
    object-fit: contain;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .video-background video {
    object-fit: contain;
  }
}

.home-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
}

.home-content {
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  font-size: calc(10px + 1vmin); /* Responsive font size */
}

.home-logo {
  width: 150px;
  margin-bottom: 20px;
}

.book-now-btn {
  background-color: #007bff; /* Theme consistent color */
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 18px; /* Slightly smaller font size for better fit */
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); /* Enhanced shadow for better visibility */
  margin-top: 20px;
}
