@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Base styles to ensure consistency across the site */
.app {
  font-family: 'Roboto', sans-serif; /* Uniform font for the whole app */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navigation styles */
.navbar {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 0.5rem 1rem;
}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.logo img {
  height: auto;
  width: 120px; /* Consistent logo size */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin-right: 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
}

.navbar a:hover,
.navbar a:focus {
  background-color: #575757;
  border-radius: 5px;
}

/* Main content styles */
.content {
  flex: 1;
  margin-top: 80px;
  padding-bottom: 50px;
}

/* Footer styles */
.footer {
  background-color: #222; /* Slightly lighter for subtle contrast */
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  width: 100%;
}

/* Social media icon enhancements */
.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-icons a {
  color: #bbb; /* Lighter for visibility */
  font-size: 24px;
  margin: 0 15px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #fff; /* Bright white on hover for effect */
}

/* Responsive adjustments for navbar */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    align-items: center;
  }

  .navbar li {
    display: block;
    margin: 5px 0;
  }

  .content {
    margin-top: 100px; /* Increased margin for mobile nav */
  }
}

/* Input styles for forms on all pages */
input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type='submit'] {
  background-color: #4caf50; /* Consider changing for consistency */
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type='submit']:hover {
  background-color: #45a049;
}
