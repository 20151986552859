@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.contact {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px; /* Added padding for better spacing on smaller screens */
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

/* Enhanced responsive adjustments for the background image */
@media (max-width: 768px) {
  .background-image {
    background-size: cover; /* keep cover but ensure the focus of the image is maintained */
    background-position: center; /* adjust this if the focus is not maintained */
  }
}

.contact-container {
  max-width: 800px;
  width: 100%; /* Ensures the container uses up to 800px but no more, adjusts to smaller widths */
  background: rgba(255, 255, 255, 0.95); /* Enhance readability against potentially busy background */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soften the shadow for a more modern look */
  margin: auto; /* Center the container on all screen sizes */
}

.contact-card {
  background-color: transparent; /* Remove additional background to use container's style */
  border: none; /* Remove border for a cleaner look */
}

.contact-card .card-title {
  color: #007bff; /* Theme color for consistency */
  font-size: 28px; /* Responsive font size can be adjusted if needed */
}

.contact-card .card-text, .contact-method .card-text {
  color: #333; /* Darker color for better readability */
  font-size: 18px; /* Adjust font size for consistency across devices */
  margin-bottom: 15px; /* Consistent spacing */
}

.contact-info {
  display: flex;
  justify-content: space-around; /* Improve spacing and distribution */
  flex-wrap: wrap;
  margin-top: 20px; /* Add space between text and contact icons */
}

.contact-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .contact-container, .contact-card {
    padding: 20px; /* Slightly reduce padding on smaller screens */
  }
}
