/* BookingForm.css */
:root {
  --form-bg-color: #f8f9fa;
  --error-color: #dc3545;
  --primary-color: #007bff;
}

.booking-form-container {
  padding: 40px 0;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-header h2 {
  margin-bottom: 10px;
}

.form-note {
  font-size: 0.9rem;
  color: #6c757d;
}

.booking-form {
  background-color: var(--form-bg-color);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

.form-control {
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.invalid-feedback {
  color: var(--error-color);
}

.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.form-actions button {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  margin: 0 10px;
}

.success-message {
  text-align: center;
  margin-top: 50px;
}

.success-message h2 {
  color: #28a745;
  margin-bottom: 20px;
}

.success-message p {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .form-header h2 {
    font-size: 1.5rem;
  }

  .form-note {
    font-size: 0.8rem;
  }

  .booking-form {
    padding: 20px;
  }

  .form-actions button {
    width: 150px;
    font-size: 16px;
    margin: 0 5px;
  }
}
